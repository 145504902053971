/********************/
/*     SETTINGS     */
/********************/

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Montserrat', sans-serif;
}

:root {
	--primary-color: #FFEFEF;
	--hover-primary: #D65E76;
	--rose-color: #FCB5B5;
	--secundary-color: #EEC95C;
	--hover-secundary: #C29C73;
	--background-color: #D65E76;
	--background-light: #FFE5BB;
	--white-color: #FFFFFF;
	--white-color-2: #FFF4E1;
	--red-color: #A6192E;
	--light-brown: #F3E3C5;
	--brown-color-1: #D6C6A8;
	--brown-color-2: #8C5A44;
	--brown-color-3: #341F17;
	--light-gray: #F1F1F1;
	--gray-color: #A49694;
	--text-color: #222222;

	/** Essen - Colors **/
	--capri-color: #24A1BA;
	--capri-hover: #0B829A;
	--cherry-color: #562F27;
	--cherry-hover: #391E18;
	--terra-color: #564D44;
	--terra-hover: #3E362E;
	--nuit-color: #2c2a2a;
	--nuit-hover: #202022;
}

.container {
	margin: 0 auto;
	max-width: 95rem;
}

.btn {
	padding: 1rem 2.2rem;
	font-size: 1rem;
	position: relative;
	margin: 2rem;
}

/********************/
/*       BLOGS      */
/********************/

.container-blog-title {
	padding: 6rem 15rem;
	display: flex;
	background: rgb(52,31,23);
	background: linear-gradient(90deg, rgba(52,31,23,1) 0%, rgba(140,90,68,1) 100%);
	color: var(--white-color);
	font-size: 3rem;
}

.container-blog-title h3 {
	font-weight: 600;
	font-style: italic;
}

.wrapper-blogs {
	display: grid;
	grid-template-columns: 3fr 1fr;
	padding: 2rem 8rem;
}

.blogs-listed {
	display: flex;
	gap: 2rem;
	flex-wrap: wrap;
}

.container-blogs {
	display: flex;
	flex-direction: column;
	gap: 3rem;
	padding: 1rem 2rem;
}

.container-blogs h3 {
	padding: 1rem;
	color: var(--brown-color-3);
	font-size: 1.7rem;
}

.container-right-wrapper {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	padding: 1rem;
}

.container-blog-categories {
	display: flex;
	flex-direction: column;
	background-color: var(--brown-color-1);
}

.contain-blog-categories {
	display: flex;
	flex-direction: column;
}

.blog-category {
	display: flex;
	padding: .8rem 1rem;
	transition: all .3s ease;
	cursor: pointer;
	color: var(--brown-color-3);
}

.blog-category:hover {
	background-color: var(--brown-color-2);
	color: var(--white-color);
}

.container-blog-search {
	position: relative;
	width: 100%;
	margin-top: 2rem;
}

.container-blog-search input {
	position: relative;
	width: 100%;
	padding: 1.1rem .7rem .7rem;
	background: transparent;
	outline: none;
	border: none;
	box-shadow: none;
	font-size: 1em;
	letter-spacing: 0.05em;
	transition: 0.5s;
	z-index: 10;
	color: var(--white-color);
}

.container-blog-search span {
	position: absolute;
	left: 0;
	padding: 1.1rem .7rem .7rem;
	pointer-events: none;
	font-size: 1em;
	letter-spacing: 0.05em;
	transition: 0.5s;
}

.container-blog-search input:valid ~ span,
.container-blog-search input:focus ~ span {
	color: #000;
	font-size: 0.75em;
	transform: translateY(-2rem);
}

.container-blog-search i {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 2px;
	background-color: var(--brown-color-3);
	border-radius: 4px;
	overflow: hidden;
	transition: 0.5s;
	pointer-events: none;
}

.container-blog-search input:valid ~ i,
.container-blog-search input:focus ~ i {
	height: 2.7rem;
}

.icon-search-blog {
	position: absolute;
	top: 1rem;
	font-size: 1.2rem;
	right: 1rem;
	z-index: 10;
	transition: all .7s ease;
	color: var(--white-color);
	opacity: 0;
	cursor: pointer;
}

.container-blog-search input:valid ~ .icon-search-blog,
.container-blog-search input:focus ~ .icon-search-blog {
	opacity: 1;
}

.container-most-recents {
	margin-top: 2rem;
	display: flex;
	flex-direction: column;
}

.contain-recents-blog {
	display: flex;
	width: 100%;
	gap: 1.5rem;
	align-items: center;
	transition: all .3s ease;
	cursor: pointer;
	padding: 0 1rem;
}

.image-recents-blog {
	width: 5rem;
	height: 5rem;
}

.image-recents-blog img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 50%;
}

.wrapper-recent-blog-info {
	width: 70%;
}

.wrapper-recent-blog-info h4 {
	color: var(--brown-color-3);
	transition: all .3s ease;
}

.wrapper-recent-blog-info h4:hover {
	color: var(--brown-color-2);
}

.container-most-popular {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.contain-popular-blog {
	display: flex;
	flex-direction: column;
	padding: .5rem;
	text-align: center;
	color: var(--brown-color-2);
	transition: all .5s ease;
	gap: .5rem;
}

.image-banner-blog {
	width: 22rem;
	height: 13rem;
}

.image-banner-blog img {
	width: 100%;
	height: 100%;
}

.date-and-rating {
	display: flex;
	justify-content: space-between;
}

.contain-popular-blog:hover {
	background-color: var(--brown-color-2);
	color: var(--white-color);
}

.order-number-blog {
	display: flex;
	padding: .5rem 1.2rem;
	align-items: center;
}

.order-number-blog p {
	font-size: 2.5rem;
	color: var(--brown-color-2);
	transition: all .3s ease;
}

.date-and-rating {
	display: flex;
	gap: 2rem;
	font-size: .8rem;
}

.contain-popular-blog:hover .order-number-blog p {
	color: var(--white-color);
}

/********************/
/*    BLOG-DETAIL   */
/********************/

.container-blog-detail {
	display: flex;
	flex-direction: column;
	padding: 1rem 5rem;
	gap: 2rem;
}

.container-header-blog {

}

.blog-detail-title {
	font-size: 2rem;
	color: var(--brown-color-3);
}

.blog-detail-info {
	display: flex;
	flex-direction: column;
	gap: 2rem;
}

.blog-detail-thumbnail {
	width: 100%;
}

.blog-detail-thumbnail img {
	width: 100%;
	height: 100%;
}

.post-description {
	font-size: 1.2rem;
}

.container-footer-blog {
	display: flex;
	justify-content: space-between;
}

.contain-blog-detail-share {
	display: flex;
	gap: .5rem;
	align-items: center;
	color: var(--brown-color-2);
}

.blog-detail-share {
	display: flex;
	color: var(--brown-color-2);
	font-size: 1.4rem;
	padding: .5rem;
	transition: all .5s ease;
}

.blog-detail-share:hover {
	color: var(--brown-color-3);
}

.autograph-text {
	font-family: 'Great Vibes', cursive !important;
	font-size: 1.5rem;
	color: var(--brown-color-2);
}

.container-blog-profile {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 1rem;
}

.container-blog-profile-img {
	width: 18rem;
	height: 18rem;
}

.text-about-wrapper {
	text-align: center;
}

.container-blog-profile-img img {
	width: 100%;
	height: 100%;
	border-radius: 50%;
}

.blog-wrapper-titles {
	display: flex;
	width: 100%;
	background-color: var(--brown-color-3);
	color: var(--white-color);
	align-items: center;
	justify-content: center;
	padding: .5rem;
}

.container-blog-social-links {
	display: flex;
	flex-wrap: wrap;
	gap: .5rem;
	padding-top: 1rem;
	justify-content: center;
}

.blog-social-media {
	display: flex;
	padding: .5rem;
	background-color: var(--white-color);
	font-size: 1.7rem;
	border: 1px solid var(--brown-color-3);
	color: var(--brown-color-3);
	transition: all .5s ease;
}

.blog-social-media:hover {
	background-color: transparent;
	border: 1px solid transparent;
}

.add-blog-favorite {
	display: flex;
	border: none;
	outline: none;
	background-color: var(--brown-color-2);
	color: var(--white-color);
	font-weight: 600;
	font-size: 1.1rem;
	padding: .8rem 1.2rem;
	transition: all .5s ease;
	cursor: pointer;
}

.add-blog-favorite:hover {
	background-color: var(--brown-color-3);
}

.added-blog-favorite {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: .5rem;
	background-color: var(--brown-color-2);
	color: var(--white-color);
	font-weight: 600;
	font-size: 1.1rem;
	padding: .8rem 1.2rem;
	width: 10rem;
}

/********************/
/*   PHONE-SCREEN   */
/********************/

@media screen and (max-width: 700px) {
	.container-blog-title {
		padding: 10rem 1rem;
	}

	.wrapper-blogs {
		display: flex;
		flex-direction: column;
		padding: 1rem;
	}

	.container-blogs {
		padding: 1rem 0;
	}

	.blogs-listed {
		align-items: center;
		justify-content: center;
	}

	.image-banner-blog {
		width: 100%;
	}

	/* Blog Detail */

	.container-blog-detail {
		padding: 1rem;
	}

	.container-footer-blog {
		display: flex;
		flex-direction: column;
	}

	.contain-blog-detail-share {
		gap: .3rem;
	}
}