/********************/
/*     SETTINGS     */
/********************/

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Montserrat', sans-serif;
}

:root {
	--primary-color: #FFEFEF;
	--hover-primary: #D65E76;
	--rose-color: #FCB5B5;
	--secundary-color: #EEC95C;
	--hover-secundary: #C29C73;
	--background-color: #D65E76;
	--background-light: #FFE5BB;
	--white-color: #FFFFFF;
	--white-color-2: #FFF4E1;
	--red-color: #A6192E;
	--light-brown: #F3E3C5;
	--light-brown-2: #FFF6E3;
	--brown-color-1: #D6C6A8;
	--brown-color-2: #8C5A44;
	--brown-color-3: #341F17;
	--light-gray: #F1F1F1;
	--gray-color: #A49694;
	--text-color: #222222;

	/** Essen - Colors **/
	--capri-color: #24A1BA;
	--capri-hover: #0B829A;
	--cherry-color: #562F27;
	--cherry-hover: #391E18;
	--terra-color: #564D44;
	--terra-hover: #3E362E;
	--nuit-color: #2c2a2a;
	--nuit-hover: #202022;
}

.container {
	margin: 0 auto;
	max-width: 95rem;
}

.btn {
	padding: 1rem 2.2rem;
	font-size: 1rem;
	position: relative;
	margin: 2rem;
}

/********************/
/*       HOME       */
/********************/

.container-main-banner {
	height: 95vh;
	background: url('../images/banner.png');
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
}

.container-main-banner img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

/*      LINES       */

.container-main-lines {
	display: flex;
	flex-direction: column;
	gap: 3rem;
	padding: 3rem 0;
	align-items: center;
}

.main-essen-title {
	display: flex;
	flex-direction: column;
	font-size: 2rem;
	width: 40rem;
	font-weight: 600;
	text-align: center;
	color: var(--brown-color-2);
}

.main-essen-title span {
	font-family: 'Great Vibes', cursive !important;
	color: var(--brown-color-2) !important;
	font-size: 3.5rem;
}

.container-essen-lines {
	display: flex;
	padding: 2rem 12rem;
	justify-content: center;
	gap: 4rem;
	align-items: center;
}

.contain-essen-lines{
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1.5rem;

}

.contain-essen-image {
	width: 15rem;
	height: 30rem;
}

.contain-essen-lines img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: .5rem;
}

.btn-see-more {
	color: var(--brown-color-2);
	font-weight: 600;
}

.btn-see-more::after,
.btn-see-more::before {
	content: "";
	border: 2px solid var(--brown-color-2);
	position: absolute;
	width: calc(100% - 6px);
	height: calc(100% - 6px);
	left: 0;
	bottom: 0;
	z-index: -1;
	transition: transform .3s ease;
}

.btn-see-more:hover::after {
	transform: translate(-5px, -5px);
}

.btn-see-more:hover::before {
	transform: translate(5px, 5px);
}

/*      ESSEN       */

.main-essen-container {
	background: url('../images/essen-banner.png');
	background-size: contain;
	height: 100vh;
	background-repeat: no-repeat;
	background-position: center;
	position: relative;
}

.contain-essen-link {
	position: absolute;
	font-size: 1.8rem;
	width: 40rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	top: 70%;
	right: 25rem;
	gap: 1rem;
}

.link-to-essen {
	display: flex;
	background-color: var(--brown-color-2);
	color: var(--white-color);
	padding: .5rem 1rem;
	transition: all .5s ease;
	border-radius: 1.5rem;
}

.link-to-essen:hover {
	background-color: var(--brown-color-3);
}

/*  CARACTERISTICS  */

.container-caracteristics {
	display: flex;
	gap: 2rem;
	padding: 1rem 15rem;
	padding-bottom: 5rem;
	justify-content: space-between;
}

.caracteristics-item {
	display: flex;
	flex-direction: column;
	gap: .5rem;
	width: 9rem;
	align-items: center;
	text-align: center;
}

.caracteristics-item-img {
	width: 4rem;
	height: 4rem;
}

.caracteristics-item-img img {
	width: 100%;
	height: 100%;
}

/*     RECIPES      */

.main-recipes-container {
	display: grid;
	padding: 2rem 15rem;
	width: 100%;
	grid-template-columns: repeat(2, 1fr);
	min-height: 90vh;
	height: 100%;
	background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('../images/banner-recipe.jpg');
	background-size: cover;
}

.contain-recipes-info {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 10rem 2rem;
	gap: 3rem;
}

.main-recipe-title {
	font-size: 2rem;
	color: var(--white-color);
}

.main-recipe-text {
	font-size: 1.2rem;
	color: var(--white-color);
}

.btn-see-recipes {
	color: var(--white-color);
	font-weight: 600;
}

.btn-see-recipes::after,
.btn-see-recipes::before {
	content: "";
	border: 2px solid var(--white-color);
	position: absolute;
	width: calc(100% - 6px);
	height: calc(100% - 6px);
	left: 0;
	bottom: 0;
	z-index: 0;
	transition: transform .3s ease;
}

.btn-see-recipes:hover::after {
	transform: translate(-5px, -5px);
}

.btn-see-recipes:hover::before {
	transform: translate(5px, 5px);
}

.container-card-swiper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.contain-card-swiper {
	width: 30rem;
}

/*      BLOGS     */

.container-main-blog {
	display: flex;
	flex-direction: column;
	padding: 5rem 22rem;
}

.contain-blog-info {
	background-color: var(--brown-color-2);
	display: flex;
	padding: 1rem 1.5rem;
	flex-direction: column;
	color: var(--white-color);
	gap: 1rem;
}

.blog-info-title {
	font-size: 2rem;
}

.blog-info-texts {
	display: flex;
	flex-direction: column;
	padding-left: 26rem;
	justify-content: right;
}

.container-featured-blogs {
	display: flex;
	flex-direction: column;
	padding: 3rem 10rem;
	justify-content: center;
	align-items: center;
	gap: 3rem;
}

.container-featured-blogs h3 {
	font-size: 2.2rem;
	color: var(--brown-color-2);
}

.container-blogs-listed {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 6rem;
}

.colored-featured-0 {
	display: flex;
	flex-direction: column;
	gap: 2rem;
	padding: 6rem 2rem 2rem;
	background-color: var(--brown-color-1);
	position: relative;
}

.colored-featured-1 {
	display: flex;
	flex-direction: column;
	gap: 2rem;
	padding: 6rem 2rem 2rem;
	background-color: var(--brown-color-2);
	position: relative;
}

.colored-featured-2 {
	display: flex;
	flex-direction: column;
	gap: 2rem;
	padding: 6rem 2rem 2rem;
	background-color: var(--brown-color-3);
	position: relative;
}

.featured-blog {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.featured-blog-title {
	top: 1.7rem;
	right: -1rem;
	position: absolute;
	text-align: center;
	background-color: var(--light-brown);
	width: 22rem;
	padding: .8rem;
}

.featured-blog-image {
	width: 16rem;
	height: 12rem;
}

.featured-blog img {
	width: 100%;
	height: 100%;
}

.container-contact-main {
	background-color: var(--brown-color-1);
}

/********************/
/*      ABOUT       */
/********************/

/* Cooks */
.container-about-cook {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	background-color: var(--brown-color-1);
	padding: 5rem 12rem;
	gap: 5rem;
}

.contain-about-cook {
	display: flex;
	flex-direction: column;
	gap: 2rem;
	justify-content: center;
	align-items: center;
}

.contain-about-cook h3 {
	font-size: 2.5rem;
	color: var(--brown-color-3);
}

.contain-about-cook p {
	color: var(--brown-color-3);
	font-size: 1.1rem;
	width: 40rem;
	text-align: center;
}

.about-cook-img {
	width: 45rem;
}

.about-cook-img img {
	width: 100%;
}

.btn-see-know {
	color: var(--brown-color-3);
	font-weight: 600;
}

.btn-see-know::after,
.btn-see-know::before {
	content: "";
	border: 2px solid var(--brown-color-3);
	position: absolute;
	width: calc(100% - 6px);
	height: calc(100% - 6px);
	left: 0;
	bottom: 0;
	z-index: 0;
	transition: transform .3s ease;
}

.btn-see-know:hover::after {
	transform: translate(-5px, -5px);
}

.btn-see-know:hover::before {
	transform: translate(5px, 5px);
}

/* Cats */
.container-about-cats {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	padding: 5rem 12rem;
	gap: 5rem;
}

.contain-about-cats {
	display: flex;
	gap: 2rem;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.contain-about-cats h3 {
	font-size: 2.5rem;
	color: var(--brown-color-2);
}

.contain-about-cats p {
	color: var(--brown-color-3);
	font-size: 1.1rem;
	width: 40rem;
}

.about-cats-img {
	width: 45rem;
}

.about-cats-img img {
	width: 100%;
}

/* Sales */
.container-about-sales {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	background-color: var(--brown-color-2);
	padding: 5rem 12rem;
	gap: 5rem;
}

.about-sales-img {
	width: 50rem;
}

.about-sales-img img {
	width: 100%;
}

.contain-about-sales {
	display: flex;
	flex-direction: column;
	gap: 3rem;
	justify-content: center;
	align-items: center;
}

.contain-about-sales h3 {
	font-size: 2.5rem;
	color: var(--white-color);
}

.contain-about-sales p {
	color: var(--white-color);
	font-size: 1.1rem;
	width: 40rem;
	text-align: center;
}

/* God */

.container-about-god {
	background: url('../images/fondo-god.jpg');
	min-height: 100vh;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	padding: 10rem 20rem;
	display: flex;
	flex-direction: column;
	gap: 2rem;
	justify-content: center;
	align-items: flex-end;
}

.container-about-god h3 {
	text-align: center;
	font-size: 4rem;
	color: var(--brown-color-3);
}

.contain-about-god {
	display: flex;
	flex-direction: column;
	gap: .5rem;
	font-size: 2rem;
	text-align: center;
}

.bible-cite {
	text-align: right !important;
	font-size: 1rem;
}

/********************/
/*     POLICIES     */
/********************/

.container-policies {
	display: flex;
	flex-direction: column;
	gap: 1.2rem;
	padding: 5rem 8rem;
}

.container-policies p {
	line-height: 1.7rem;
}

.subtitle-policies {
	font-weight: 600;
	gap: .2rem;
}

.subtitle-policies span{
	font-weight: 400;
}

.icon-policies {
	padding-right: .4rem;
	font-size: .8rem;
}

/********************/
/*      CONTACT     */
/********************/

.container-contact {
	display: flex;
	flex-direction: column;
}

.contact-wrapper {
	display: grid;
	grid-template-columns: 3fr 1fr;
}

.main-contact {
	height: 80vh;
}

.menu-contact {
	background-color: var(--cherry-color);
}

.container-contact-page {
	background-color: var(--brown-color-1);
}

/********************/
/*     ERROR-404    */
/********************/

.container-error404 {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	min-height: 65.5vh;
	background: url('../images/login-banner.png');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
}

.container-error404 p {
	font-size: 1.1rem;
	color: var(--brown-color-3);
}

.required-fields-link {
	transition: all .5s ease;
	color: var(--brown-color-3);
	font-weight: 600;
}

.required-fields-link:hover {
	color: var(--white-color);
}

.container-error404-image {
	width: 35rem;
}

.container-error404-image img {
	width: 100%;
}

/********************/
/*   PHONE-SCREEN   */
/********************/

@media screen and (max-width: 700px) {
	.container-main-banner {
		margin-top: 5rem;
		width: 100%;
		height: 90vh;
		background: url('../images/banner-phone.png');
		background-size: contain;
		background-repeat: no-repeat;
	}	

	/* Essen-Lines */

	.container-main-lines {
		width: 100%;
	}

	.main-essen-title {
		width: 100%;
	}

	.container-essen-lines {
		display: flex;
		flex-direction: column;
		width: 100%;
		padding: 2rem 1rem;
	}

	.contain-essen-image {
		width: 22rem;
		height: 16rem;
	}

	.contain-essen-lines img {
		object-fit: cover;
	}

	/* Essen */
	
	.main-essen-container {
		width: 100%;
		height: 90vh;
		padding: 8rem 0;
		background: url('../images/promociones-phone.png');
		background-position: bottom;
		background-repeat: no-repeat;
		background-size: contain;
	}

	.contain-essen-link {
		width: 20rem;
		padding: 1rem 0;
		top: 60%;
		left: 5rem;
		font-size: 1.6rem;
	}

	/* Caracteristics */

	.container-caracteristics {
		display: flex;
		flex-wrap: wrap;
		padding: 1rem;
		gap: 2rem;
		justify-content: center;
	}

	/* Recipes */

	.container-card-swiper {
		width: 100%;
	}

	.contain-recipes-info {
		padding: 1rem;
		gap: 1rem;
	}

	.contain-card-swiper {
		width: 100%;
	}

	/* Blogs */

	.container-main-blog {
		padding: 0;
		width: 100%;
	}

	.contain-blog-info {
		flex-direction: column;
	}

	.blog-info-texts {
		padding: 0;
	}

	.container-featured-blogs {
		display: flex;
		flex-direction: column;
		width: 100%;
		padding: 3rem 1rem;
	}

	.container-blogs-listed {
		display: flex;
		flex-direction: column;
		gap: 2rem;
		justify-content: center;
		align-items: center;
	}

	/* ERROR-404 */
	.container-error404 {
		text-align: center;
	}

	.container-error404-image {
		width: 100%;
	}

	/* ABOUT */

	/* Cooks */
	.container-about-cook {
		display: flex;
		flex-direction: column;
		padding: 5rem 2rem;
	}

	.about-cook-img {
		width: 100%;
	}

	.contain-about-cook h3 {
		text-align: center;
	}

	.contain-about-cook p {
		width: 100%;
	}

	/* Cats */
	.container-about-cats {
		display: flex;
		flex-direction: column;
		padding: 5rem 2rem;
	}

	.contain-about-cats h3 {
		text-align: center;
	}

	.contain-about-cats p {
		width: 100%;
	}

	.about-cats-img {
		width: 100%;
	}

	/* Sales */
	.container-about-sales {
		display: flex;
		flex-direction: column;
		padding: 5rem 2rem;
	}

	.contain-about-sales h3 {
		text-align: center;
	}

	.contain-about-sales p {
		width: 100%;
	}

	.about-sales-img {
		width: 100%;
	}

	/* God */
	.container-about-god {
		padding: 5rem 2rem;
		justify-content: flex-end;
		background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('../images/fondo-god.jpg');
		background-size: cover;
		background-position: center;
		color: var(--white-color);
	}

	.container-about-god h3 {
		color: var(--white-color);
	}
}

@media screen and (max-width: 390px) {
	.contain-essen-link {
		font-size: 1.1rem;
		width: 15rem;
	}

	.contain-essen-image {
		width: 20rem;
	}
}