/********************/
/*     SETTINGS     */
/********************/

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Montserrat', sans-serif;
}

:root {
	--primary-color: #FFEFEF;
	--hover-primary: #D65E76;
	--rose-color: #FCB5B5;
	--secundary-color: #EEC95C;
	--hover-secundary: #C29C73;
	--background-color: #D65E76;
	--background-light: #FFE5BB;
	--white-color: #FFFFFF;
	--white-color-2: #FFF4E1;
	--red-color: #A6192E;
	--light-brown: #F3E3C5;
	--light-brown-2: #FFF6E3;
	--brown-color-1: #D6C6A8;
	--brown-color-2: #8C5A44;
	--brown-color-3: #341F17;
	--light-gray: #F1F1F1;
	--gray-color: #A49694;
	--text-color: #222222;

	/** Essen - Colors **/
	--capri-color: #24A1BA;
	--capri-hover: #0B829A;
	--cherry-color: #562F27;
	--cherry-hover: #391E18;
	--terra-color: #564D44;
	--terra-hover: #3E362E;
	--nuit-color: #2c2a2a;
	--nuit-hover: #202022;
}

.container {
	margin: 0 auto;
	max-width: 95rem;
}

.btn {
	padding: 1rem 2.2rem;
	font-size: 1rem;
	position: relative;
	margin: 2rem;
}

/********************/
/*      RECIPES     */
/********************/

.container-recipes-title {
	padding: 6rem 15rem;
	display: flex;
	background: rgb(52,31,23);
	background: linear-gradient(90deg, rgba(52,31,23,1) 0%, rgba(140,90,68,1) 100%);
	color: var(--white-color);
	font-size: 3rem;
}

.container-recipes-title h3 {
	font-weight: 600;
	font-style: italic;
}

.pagination-controls-recipe {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.container-recipe-items {
	padding: 5rem 10rem;
	display: grid;
	grid-gap: 3rem;
	min-height: 22.4vh;
}

.shop-recipes {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
	grid-gap: 1rem;
	padding: .5rem;
}

.container-recipe-search {
	display: flex;
	width: 25rem;
	margin-left: 12rem;
}

.input-search-recipe {
	position: relative;
	width: 100%;
	margin-top: 2rem;
}

.input-search-recipe input {
	position: relative;
	width: 100%;
	padding: 1.1rem .7rem .7rem;
	background: transparent;
	outline: none;
	border: none;
	box-shadow: none;
	font-size: 1em;
	letter-spacing: 0.05em;
	transition: 0.5s;
	z-index: 10;
	color: var(--white-color);
}

.input-search-recipe span {
	position: absolute;
	left: 0;
	padding: 1.1rem .7rem .7rem;
	pointer-events: none;
	font-size: 1em;
	letter-spacing: 0.05em;
	transition: 0.5s;
}

.input-search-recipe input:valid ~ span,
.input-search-recipe input:focus ~ span {
	color: #000;
	font-size: 0.75em;
	transform: translateY(-2rem);
}

.input-search-recipe i {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 2px;
	background-color: var(--hover-secundary);
	border-radius: 4px;
	overflow: hidden;
	transition: 0.5s;
	pointer-events: none;
}

.input-search-recipe input:valid ~ i,
.input-search-recipe input:focus ~ i {
	height: 2.7rem;
}

.icon-search-blog {
	position: absolute;
	top: .9rem;
	font-size: 1.5rem;
	right: 1rem;
	z-index: 10;
	transition: all .7s ease;
	color: var(--white-color);
	opacity: 0;
	cursor: pointer;
	background-color: transparent;
	border: none;
	outline: none;
}

.input-search-recipe input:valid ~ .icon-search-blog,
.input-search-recipe input:focus ~ .icon-search-blog {
	opacity: 1;
}

/********************/
/*   RECIPE-DETAIL  */
/********************/

.container-detail-recipes {
	display: flex;
	flex-direction: column;
	padding: 5rem;
	padding-top: 0;
	background-color: var(--light-brown-2);
}

.contain-recipe {
	display: grid;
	grid-template-columns: 5fr 3fr;
	grid-gap: 2rem;
	padding: 4rem 2rem;
}

.container-image-recipe {
	width: 50rem;
	height: 50rem;
}

.container-image-recipe img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.container-recipe-info {
	display: flex;
	padding: 3rem 0;
	flex-direction: column;
	gap: 1rem;
}

.container-title-recipe {
	display: flex;
	justify-content: space-between;
}

.container-favorite-recipe {
	display: flex;
	gap: 1rem;
	font-size: 1.1rem;
	text-align: center;
	transition: all .5s ease;
	cursor: pointer;
	padding: .5rem;
	align-items: center;
	border-radius: 1rem;
	color: var(--red-color);
	width: 4rem;
	justify-content: center;
}

.favorite-recipe-icon {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 2rem;
}

.detail-recipe-title {
	font-weight: 600;
	font-size: 1.8rem;
}

.container-duration-dinners {
	display: flex;
	gap: 2rem;
	align-items: center;
}

.icon-duration-dinner {
	font-size: 1.6rem;
}

.duration-dinner-description {
	display: flex;
	align-items: center;
	gap: .5rem;
 }

.container-detail-details,
.container-detail-measures,
.container-detail-description {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: .7rem 0;
	border-bottom: 1px solid #e4e4e4;
}

.toggle-description {
	display: flex;
	cursor: pointer;
	align-items: center;
	justify-content: space-between;
}

.detail-description {
	padding-top: 1.5rem;
}

.detail-preparation {
	padding-top: 1.5rem;
}

.detail-details span {
	font-weight: 400;
}

.container-ingredients {
	display: flex;
	flex-direction: column;
	padding-top: 1rem;
	gap: .3rem;
}

.contain-ingredients {
	display: flex;
	flex-direction: column;
}

.contain-ingredient {
	display: grid;
	grid-template-columns: 1fr 5fr;
}

.contain-circle-quantity {
	display: flex;
	gap: .3rem;
	align-items: center;
}

.circle-ingredient {
	font-size: .5rem;
}

.container-share-with {
	display: flex;
	justify-content: space-between;
	padding: .7rem 0;
	align-items: center;
	border-bottom: 1px solid #e4e4e4;
}

.social-share-with {
	display: flex;
	gap: .5rem;
	
}

.recipe-icons-share {
	color: var(--brown-color-3);
	cursor: pointer;
	transition: all .3s ease;
}

.recipe-icons-share:hover {
	color: var(--brown-color-2);
}

/********************/
/*   PHONE-SCREEN   */
/********************/

@media screen and (max-width: 700px) {
	.container-recipes-title {
		padding: 10rem 1rem;
	}

	.container-recipe-search {
		margin-left: 1rem;
		width: 22rem;
	}

	.container-recipe-items {
		padding: 3rem .2rem;
	}

	.shop-recipes {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
		gap: 1rem;
		padding: .5rem;
	}

	/* Recipe Detail */

	.container-detail-recipes {
		padding: 6rem 1rem;
	}

	.contain-recipe {
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	.container-image-recipe {
		width: 100%;
		height: 20rem;
	}

	.container-duration-dinners {
		align-items: center;
		justify-content: center;
	}

	.contain-ingredient {
		display: flex;
		gap: .5rem;
	}
}

@media screen and (max-width: 390px) {
	.container-recipe-search {
		width: 20rem;
	}
}