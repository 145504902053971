/********************/
/*     SETTINGS     */
/********************/

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Montserrat', sans-serif;
}

:root {
	--primary-color: #FFEFEF;
	--hover-primary: #D65E76;
	--rose-color: #FCB5B5;
	--secundary-color: #EEC95C;
	--hover-secundary: #C29C73;
	--background-color: #D65E76;
	--background-light: #FFE5BB;
	--white-color: #FFFFFF;
	--white-color-2: #FFF4E1;
	--red-color: #A6192E;
	--light-brown: #F3E3C5;
	--light-brown-2: #FFF6E3;
	--brown-color-1: #D6C6A8;
	--brown-color-2: #8C5A44;
	--brown-color-3: #341F17;
	--light-gray: #F1F1F1;
	--gray-color: #A49694;
	--text-color: #222222;

	/** Essen - Colors **/
	--capri-color: #24A1BA;
	--capri-hover: #0B829A;
	--aqua-color: #24C5E6;
	--aqua-hover: #1B95AE;
	--cherry-color: #562F27;
	--cherry-hover: #391E18;
	--terra-color: #564D44;
	--terra-hover: #3E362E;
	--nuit-color: #2c2a2a;
	--nuit-hover: #202022;
}

.container {
	margin: 0 auto;
	max-width: 95rem;
}

.btn {
	padding: 1rem 2.2rem;
	font-size: 1rem;
	position: relative;
	margin: 2rem;
}

/********************/
/*     PRODUCT      */
/********************/

.container-shop-title {
	padding: 6rem 15rem;
	display: flex;
	background: rgb(52,31,23);
	background: linear-gradient(90deg, rgba(52,31,23,1) 0%, rgba(140,90,68,1) 100%);
	color: var(--white-color);
	font-size: 3rem;
}

.container-shop-title h3 {
	font-weight: 600;
	font-style: italic;
}

.pagination-controls {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.container-shop {
	padding: 5rem 10rem;
	display: grid;
	grid-template-columns:  1fr 3fr;
	grid-gap: 3rem;
	border-top: 1px solid #ddd;
}

.category-wrapper {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	padding: 1rem 2rem;
}

.container-category-wrapper {
	display: flex;
	flex-direction: column;
	gap: .5rem;
	border-bottom: 2px solid #ddd;
	padding: .5rem 0;
	font-size: .9rem;
	font-weight: 500;
}

.input-category-wrapper {
	display: flex;
	gap: .5rem;
}

.container-filter-products {
	display: flex;
	flex-direction: column;
	gap: .5rem;
}

.input-category-products {
	display: flex;
	gap: .8rem;
	font-style: italic;
	font-weight: 600;
	color: var(--brown-color-2);
}

.shop-products {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
	gap: 1rem;
	padding: .5rem;
}

.pagination-controls {
	display: flex;
	gap: .5rem;
	flex-direction: row;
}

.pagination-button {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: .2;
	padding: .8rem;
	border: none;
	outline: none;
	cursor: pointer;
	background-color: transparent;
	color: var(--brown-color-2);
	font-size: 1.1rem;
}

.pagination-button:disabled {
	color: var(--brown-color-1);
}

.pagination-button-icon {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-card-wrapper {
	display: flex;
	justify-content: center;
}

.disclosure-filter {
	display: flex;
	width: 100%;
	justify-content: space-between;
	padding: .5rem 0;
	align-items: center;
	background-color: transparent;
	border: none;
	cursor: pointer;
	font-weight: 500;
	font-size: .9rem;
	border-bottom: 2px solid #ddd;
}

.disclosure-panel {
	display: flex;
	flex-direction: column;
	gap: .5rem;
	border-bottom: 2px solid #ddd;
	padding: .5rem 0;
	font-size: .9rem;
	font-weight: 500;
}

.select-filter-wrapper {
	display: flex;
	justify-content: space-between;
}

.submit-button-filter {
	display: flex;
	padding: .5rem;
	border: none;
	justify-content: center;
	background-color: var(--primary-color);
	cursor: pointer;
	color: var(--light-text);
	border-radius: .5rem;
	transition: all .5s ease;
}

.submit-button-filter:hover {
	background-color: var(--hover-primary);
}

/********************/
/*  PRODUCT-DETAIL  */
/********************/

.container-detail-products {
	display: flex;
	flex-direction: column;
	padding: 5rem;
	padding-top: 0;
}

.pay-methods {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 1rem;
}

.contain-pay-methods {
	display: flex;
	gap: .5rem;
	align-items: center;
}

.contain-pay-methods img {
	width: 3.5rem;
	object-fit: contain;
}

.promo-active {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background-color: #00000070;
	opacity: 0;
	pointer-events: none;
	transition: var(--transition-2);
	z-index: 1;
	transition: all .5s ease;
}

.promo-active img {
	transform: translate(140%, 6.5rem);
	width: 32rem;
	object-fit: contain;
}

.promo-active.active {
	opacity: 1;
	pointer-events: all;
}

.promos-open {
	cursor: pointer;
	font-weight: 600;
	color: var(--brown-color-3);
}

.close-promos {
	font-size: 2rem;
	color: var(--brown-color-3);
	position: absolute;
	top: 6.6rem;
	left: 62.6%;
	cursor: pointer;
}

.contain-product {
	display: grid;
	grid-template-columns: 5fr 3fr;
	grid-gap: 2rem;
	padding: 4rem 2rem;
}

.container-image-product {
	width: 50rem;
	height: 50rem;
}

.container-image-product img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.container-product-info {
	display: flex;
	padding: 3rem 0;
	flex-direction: column;
	gap: 1rem;
}

.container-title-fav {
	display: flex;
	justify-content: space-between;
}

.container-favorite-product {
	display: flex;
	font-size: 1.1rem;
	text-align: center;
	transition: all .5s ease;
	cursor: pointer;
	padding: .5rem;
	align-items: center;
	border-radius: 1rem;
	color: var(--white-color);
	width: 4rem;
	justify-content: center;
}

.favorite-icon {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 2rem;
}

.detail-title {
	font-weight: 600;
	font-size: 2.5rem;
}

.toggle-measures-image {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 1rem;
	width: 100%;
}

.toggle-measures-image img {
	width: 100%;
}

.container-detail-details,
.container-detail-measures,
.container-detail-description {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: .7rem 0;
	border-bottom: 1px solid #e4e4e4;
}

.toggle-description {
	display: flex;
	cursor: pointer;
	align-items: center;
	justify-content: space-between;
}

.detail-description {
	padding-top: 1.5rem;
}

.detail-details {
	padding-top: 1.5rem;
	font-weight: 600;
}

.detail-details span {
	font-weight: 400;
}

.container-share-with {
	display: flex;
	justify-content: space-between;
	padding: .7rem 0;
	align-items: center;
	border-bottom: 1px solid #e4e4e4;
}

.social-share-with {
	display: flex;
	gap: .5rem;
	
}

.icons-share {
	color: var(--brown-color-2);
	cursor: pointer;
	transition: all .3s ease;
}

.icons-share:hover {
	color: var(--brown-color-3);
}

.whatsapp-button-link {
	display: flex;
	background-color: var(--brown-color-2);
	border-radius: 1rem;
	justify-content: center;
	align-items: center;
	gap: 1.5rem;
	padding: .5rem;
	color: var(--white-color);
	transition: all .5s ease;
}

.whatsapp-button-link:hover {
	background-color: var(--brown-color-3);
}

.container-whatsapp-link {
	width: 2rem;
	height: 2rem;
}

.container-whatsapp-link img {
	width: 100%;
	height: 100%;
}

/********************/
/*   ESSEN-COLORS   */
/********************/

.capri {
	color: var(--capri-color) !important;
}

.capri:hover {
	color: var(--capri-hover) !important;
}

.capri-background {
	background-color: var(--capri-color) !important;
}

.capri-background:hover {
	background-color: var(--capri-hover) !important;
}

.terra {
	color: var(--terra-color) !important;
}

.terra-background {
	background-color: var(--terra-color) !important;
}

.terra:hover {
	color: var(--terra-hover) !important;
}

.terra-background:hover {
	background-color: var(--terra-hover) !important;
}

.cherry {
	color: var(--cherry-color) !important;
}

.cherry-background {
	background-color: var(--cherry-color) !important;
}

.cherry:hover {
	color: var(--cherry-hover) !important;
}

.cherry-background:hover {
	background-color: var(--cherry-hover) !important;
}

.nuit {
	color: var(--nuit-color) !important;
}

.nuit-background {
	background-color: var(--nuit-color) !important;
}

.nuit:hover {
	color: var(--nuit-hover) !important;
}

.nuit-background:hover {
	background-color: var(--nuit-hover) !important;
}

.aqua {
	color: var(--aqua-color) !important;
}

.aqua-background {
	background-color: var(--aqua-color) !important;
}

.aqua:hover {
	color: var(--aqua-hover) !important;
}

.aqua-background:hover {
	background-color: var(--aqua-hover) !important;
}

/********************/
/*   PHONE-SCREEN   */
/********************/

@media screen and (max-width: 700px) {
	.container-shop-title {
		padding: 10rem 1rem;
	}

	.container-shop {
		display: flex;
		flex-direction: column;
		padding: 2rem .2rem;
	}

	.shop-products {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
		gap: 1rem;
		padding: .5rem;
	}

	/* Product Detail */
	.container-detail-products {
		padding: 6rem 1rem;
	}

	.contain-product {
		display: flex;
		flex-direction: column;
	}

	.container-title-fav {
		justify-content: center;
		align-items: center;
	}

	.container-favorite-product {
		height: 3rem;
		width: 4rem;
	}

	.container-image-product {
		width: 100%;
		height: 18rem;
	}

	.detail-title {
		font-size: 1.8rem;
	}
	.promo-active {
		position: fixed;
		top: 0;
		left: 0;
		background-color: #00000070;
		opacity: 0;
		pointer-events: none;
		transition: var(--transition-2);
		z-index: 1;
		transition: all .5s ease;
	}

	.promo-active img {
		width: 100%;
		object-fit: contain;
		transform: translate(0, 4.9rem);
	}

	.promo-active.active {
		opacity: 1;
		pointer-events: all;
	}
	.close-promos {
		top: 6rem;
		left: 85%;
	}
}