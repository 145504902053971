/********************/
/*     SETTINGS     */
/********************/

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Montserrat', sans-serif;
}

:root {
	--primary-color: #FFEFEF;
	--hover-primary: #D65E76;
	--rose-color: #FCB5B5;
	--secundary-color: #EEC95C;
	--hover-secundary: #C29C73;
	--background-color: #D65E76;
	--background-light: #FFE5BB;
	--white-color: #FFFFFF;
	--white-color-2: #FFF4E1;
	--red-color: #A6192E;
	--light-brown: #F3E3C5;
	--brown-color-1: #D6C6A8;
	--brown-color-2: #8C5A44;
	--brown-color-3: #341F17;
	--light-gray: #F1F1F1;
	--gray-color: #A49694;
	--text-color: #222222;

	/** Essen - Colors **/
	--capri-color: #24A1BA;
	--capri-hover: #0B829A;
	--cherry-color: #562F27;
	--cherry-hover: #391E18;
	--terra-color: #564D44;
	--terra-hover: #3E362E;
	--nuit-color: #2c2a2a;
	--nuit-hover: #202022;
}

.container {
	margin: 0 auto;
	max-width: 95rem;
}

.btn {
	padding: 1rem 2.2rem;
	font-size: 1rem;
	position: relative;
	margin: 2rem;
}

/********************/
/*      NAVBAR      */
/********************/

.container-navbar {
	position: sticky;
	top: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: .8rem 15rem;
	background-color: var(--white-color);
	z-index: 1000;
}

.span-bar {
	height: 2px;
	display: inline-block;
	width: 0%;
	background-color: var(--brown-color-2);
	position: absolute;
	left: 0;
	bottom: -0.125rem;
	transition: .3s ease;
}

.container-logo {
	color: var(--brown-color-2);
	font-size: 1.4rem;
}

.container-navlinks {
	display: flex;
	gap: 2rem;
}

.navlink-social {
	display: flex;
	gap: 2rem;
}

.navlink-item {
	position: relative;
	color: var(--brown-color-3);
	padding: .2rem 0;
	font-weight: 600;
	transition: all .6s ease;
	font-size: 1rem;
}

.navlink-item:hover .span-bar{
	width: 100%;
}

.active span{
	width: 100%;
}

.navlink-item:hover {
	color: var(--brown-color-2);
}

.active {
	color: var(--brown-color-2);
}

.social-active {
	background-color: var(--secundary-color);
}

.logo-position {
	font-family: 'Great Vibes', cursive !important;
	font-size: 2.3rem;
	color: var(--brown-color-2);
}

#menu-toggle {
	display: none;
	background: none;
	border: none;
	cursor: pointer;
	padding: 0;
}

.line {
	background-color: var(--brown-color-3);
	display: block;
	width: 30px;
	height: 2px;
	margin: 5px 0;
	border-radius: 50%;
	transition: all .3s ease-out;
}

.line-1 {
	transform: rotate(45deg) translate(0.6rem);
}

.line-2 {
	opacity: 0;
}

.line-3 {
	transform: rotate(-45deg) translate(0.6rem);
}

/********************/
/*      FOOTER      */
/********************/

.container-footer {
	display: flex;
	flex-direction: column;
}

.container-footer-info {
	display: flex;
	padding: 2rem 8rem;
	justify-content: space-around;
	background-color: var(--brown-color-3);
}

.container-footer-rights {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 1rem;
	background-color: var(--background-light);
	color: var(--dark-text);
}

.footer-subtitle {
	color: var(--white-color);
	font-weight: 400;
	font-size: 1.1rem;
	padding-bottom: .5rem;
}

.contain-footer-info {
	display: flex;
	flex-direction: column;
	gap: .7rem;
}

.footer-main-menu {
	display: flex;
	flex-direction: column;
	gap: .7rem;
}

.footer-links {
	color: var(--white-color);
	transition: all .3s ease;
}

.footer-links:hover {
	color: var(--brown-color-1);
}

.contain-logo {
	font-size: 1.4rem;
	color: var(--white-color);
}

.footer-contact-item {
	display: flex;
	gap: .6rem;
	font-size: 1rem;
	color: var(--white-color);
}

.icon-footer {
 	color: var(--brown-color-2);
}

.contain-footer-social {
 	display: flex;
 	align-items: center;
 	justify-content: center;
 	gap: .5rem;
 	font-size: 1.5rem;
}

.social-footer-links {
 	display: flex;
 	justify-content: center;
 	align-items: center;
 	padding: .5rem;
 	background-color: var(--brown-color-2);
 	color: var(--white-color);
 	transition: all .5s ease;
}

.link-to-kching {
	font-weight: 600;
	color: #000;
}

.social-footer-links:hover {
 	background-color: var(--white-color);
}

.facebook:hover {
 	color: #1877F2 !important;
}

.instagram:hover {
 	color: #DD2A7B !important;
}

.pinterest:hover {
 	color: #BD081C !important;
}

.telegram:hover {
	color: #24A1DE !important;
 }

 .youtube:hover {
 	color: #C4302B !important;
 }

.whatsapp:hover {
	color: #25D366 !important;
}

/********************/
/*    DASHBOARD     */
/********************/

.container-dashboard-title {
	font-size: 1.4rem;
	padding: 1rem 0;
	color: var(--brown-color-3);
}

.container-dashboard {
	display: grid;
	grid-template-columns: 1fr 3fr;
	min-height: 60.1vh;
	height: 100%;
}

.container-dashboard-items {
	display: flex;
	flex-direction: column;
	padding: 0 5rem 1rem;
	min-height: 65.6vh;
}

.dashboard-item {
	display: flex;
	gap: 2rem;
	padding: .7rem;
	font-weight: 600;
	border-radius: 1rem;
	align-items: center;
	cursor: pointer;
	font-size: 1.2rem;
	color: var(--brown-color-2);
	border-bottom: 1px solid transparent;
	border-right: 1px solid transparent;
	transition: all .4s ease;
}

.dashboard-sub-item:hover,
.dashboard-item:hover {
	border-bottom: 1px solid var(--brown-color-2);
	border-right: 1px solid var(--brown-color-2);
	background-color: var(--light-brown);
	color: var(--brown-color-2);
}

.item-active {
	border-bottom: 1px solid var(--brown-color-2);
	border-right: 1px solid var(--brown-color-2);
	background-color: var(--light-brown);
}

.contain-dashboard-cards {
	background-color: var(--background-light);
}

.wish-icon {
	font-size: 2rem;
}

.container-dashboard-subitem {
	display: flex;
	flex-direction: column;
	padding-left: 2rem;
}

.dashboard-sub-item {
	display: flex;
	gap: 2rem;
	padding: .7rem;
	font-weight: 600;
	border-radius: 1rem;
	align-items: center;
	cursor: pointer;
	font-size: 1.2rem;
	color: var(--brown-color-2);
	border-bottom: 1px solid transparent;
	border-right: 1px solid transparent;
	transition: all .4s ease;
}

.contain-favorites-cards {
	display: flex;
	padding: 3rem;
	min-height: 65.7vh;
	background-color: var(--white-color-2);
	box-shadow: 5px 0px 5px 0px rgba(138,138,138,0.75) inset;
	-webkit-box-shadow: 5px 0px 5px 0px rgba(138,138,138,0.75) inset;
	-moz-box-shadow: 5px 0px 5px 0px rgba(138,138,138,0.75) inset;
}

.cards-contain-wish {
	display: flex;
	flex-wrap: wrap;
	gap: 1.5rem;
}

.sub-item-noactive {
	color: var(--brown-color-1);
}

.container-profile {
	display: flex;
	flex-direction: column;
	padding: 3rem;
	background-color: var(--white-color-2);
	box-shadow: 5px 0px 5px 0px rgba(138,138,138,0.75) inset;
	-webkit-box-shadow: 5px 0px 5px 0px rgba(138,138,138,0.75) inset;
	-moz-box-shadow: 5px 0px 5px 0px rgba(138,138,138,0.75) inset;
}

.profile-info {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	width: 28em;
}

.profile-info-text {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: .6rem;
	color: var(--brown-color-2);
}

.profile-info-text span {
	color: var(--brown-color-3);
	font-weight: 600;
}

.container-profile-handler {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	padding: 2rem 0;
	width: 15rem;
}

.profile-handler-link {
	display: flex;
	border: none;
	gap: .5rem;
	justify-content: center;
	background-color: var(--brown-color-2);
	cursor: pointer;
	padding: .5rem 1rem;
	color: var(--white-color);
	font-size: 1rem;
	transition: all .5s ease;
	border-radius: 1rem;
}

.logout-button {
	display: flex;
	border: none;
	gap: .5rem;
	background-color: var(--brown-color-2);
	cursor: pointer;
	padding: .5rem 1rem;
	color: var(--white-color);
	font-size: 1rem;
	justify-content: center;
	transition: all .5s ease;
	border-radius: 1rem;
}

.profile-handler-link:hover,
.logout-button:hover {
	background-color: var(--brown-color-3);
}

/********************/
/*   EDIT-PROFILE   */
/********************/

.profile-info-edit {
	width: 18rem;
}

.edit-profile-input {
	position: relative;
	width: 100%;
	margin-top: 2rem;
}

.edit-profile-input input {
	position: relative;
	width: 100%;
	padding: 1.1rem .7rem .7rem;
	background: transparent;
	outline: none;
	border: none;
	box-shadow: none;
	font-size: 1em;
	letter-spacing: 0.05em;
	transition: 0.5s;
	z-index: 10;
	color: var(--white-color);
}

.edit-profile-input span {
	position: absolute;
	left: 0;
	padding: 1.1rem .7rem .7rem;
	pointer-events: none;
	font-size: 1em;
	letter-spacing: 0.05em;
	transition: 0.5s;
}

.edit-profile-input input:valid ~ span,
.edit-profile-input input:focus ~ span {
	color: #000;
	font-size: 0.75em;
	transform: translateY(-2rem);
}

.edit-profile-input i {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 2px;
	background-color: var(--brown-color-2);
	border-radius: 4px;
	overflow: hidden;
	transition: 0.5s;
	pointer-events: none;
}

.edit-profile-input input:valid ~ i,
.edit-profile-input input:focus ~ i {
	height: 2.7rem;
}

/********************/
/*   PHONE-SCREEN   */
/********************/

@media screen and (max-width: 700px) {
	#menu-toggle {
		display: block;
	}

	.container-navlinks {
		display: none;
	}

	.container-navbar {
		display: flex;
		justify-content: space-between;
		width: 100%;
		padding: 1rem;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 1000;
	}

	.navbar {
		display: flex;
		background-color: var(--white-color);
		flex-direction: column;
		position: absolute;
		right: -500px;
		top: 77px;
		width: 100%;
		align-items: center;
		padding: 2rem 0;
		transition: all .5s ease;
		visibility: hidden;
		z-index: 2;
	}

	.navbar.menu-active {
		visibility: visible;
		transform: translateX(-500px);
	}

	/* Footer */

	.container-footer-info {
		display: flex;
		flex-direction: column;
		gap: 1rem;
	}

	.container-footer-info {
		padding: 1rem;
	}

	.contain-footer-social {
		gap: 1.5rem;
	}

	.contain-footer-info {
		justify-content: center;
		align-items: center;
	}

	.footer-main-menu {
		display: flex;
		flex-direction: row;
		gap: 1rem;
		flex-wrap: wrap;
	}

	.footer-subtitle {
		font-size: 1.4rem;
	}

	.container-footer-rights {
		text-align: center;
	}

	/* Dashboard */

	.container-dashboard {
		display: flex;
		flex-direction: column;
		padding-top: 5rem;
		width: 100%;
	}

	.container-dashboard-items {
		text-align: center;
	}

	.container-profile {
		box-shadow: none;
	}

	.profile-info {
		width: 100%;
	}

	.profile-info-text {
		display: flex;
		gap: .5rem;
		align-items: center;
		justify-content: center;
	}

	.container-profile-handler {
		justify-content: center;
		align-items: center;
		width: 100%;
	}

	.contain-favorites-cards {
		box-shadow: none;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
}