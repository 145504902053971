/********************/
/*     SETTINGS     */
/********************/

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Montserrat', sans-serif;
}

:root {
	--primary-color: #FFEFEF;
	--hover-primary: #D65E76;
	--rose-color: #FCB5B5;
	--secundary-color: #EEC95C;
	--hover-secundary: #C29C73;
	--background-color: #D65E76;
	--background-light: #FFE5BB;
	--white-color: #FFFFFF;
	--white-color-2: #FFF4E1;
	--red-color: #A6192E;
	--light-brown: #F3E3C5;
	--brown-color-1: #D6C6A8;
	--brown-color-2: #8C5A44;
	--brown-color-3: #341F17;
	--light-gray: #F1F1F1;
	--gray-color: #A49694;
	--text-color: #222222;

	/** Essen - Colors **/
	--capri-color: #24A1BA;
	--capri-hover: #0B829A;
	--cherry-color: #562F27;
	--cherry-hover: #391E18;
	--terra-color: #564D44;
	--terra-hover: #3E362E;
	--nuit-color: #2c2a2a;
	--nuit-hover: #202022;
}

.container {
	margin: 0 auto;
	max-width: 95rem;
}

.btn {
	padding: 1rem 2.2rem;
	font-size: 1rem;
	position: relative;
	margin: 2rem;
}

/********************/
/*       LOGIN      */
/********************/

.container-login {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 5rem 0;
	background-image: url('../images/login-banner.png');
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
}

.container-form {
	position: relative;
	width: 26rem;
	height: 30rem;
	background-color: var(--white-color-2);
	border-radius: .5rem;
	overflow: hidden;
}

.container-form .form-content {
	position: absolute;
	inset: 4px;
	background-color: var(--white-color);
	padding: 3rem 2.5rem;
	border-radius: .5rem;
	z-index: 2;
	display: flex;
	flex-direction: column;
}

.container-form::before {
	content: '';
	position: absolute;
	top: -50%;
	left: -50%;
	width: 26rem;
	height: 30rem;
	background: linear-gradient(0deg, transparent, transparent, var(--brown-color-1), var(--brown-color-1), var(--brown-color-1));
	z-index: 1;
	transform-origin: bottom right;
	animation: animate 6s linear infinite;
}

.container-form::after {
	content: '';
	position: absolute;
	top: -50%;
	left: -50%;
	width: 26rem;
	height: 30rem;
	background: linear-gradient(0deg, transparent, transparent, var(--brown-color-1), var(--brown-color-1), var(--brown-color-1));
	z-index: 1;
	transform-origin: bottom right;
	animation: animate 6s linear infinite;
	animation-delay: -3s;
}

.border-line {
	position: absolute;
	top: 0;
	inset: 0;
}

.border-line::before {
	content: '';
	position: absolute;
	top: -50%;
	left: -50%;
	width: 26rem;
	height: 35rem;
	background: linear-gradient(0deg, transparent, transparent, var(--brown-color-2), var(--brown-color-2), var(--brown-color-2));
	z-index: 1;
	transform-origin: bottom right;
	animation: animate 6s linear infinite;
	animation-delay: -1.5s;
}

.border-line::after {
	content: '';
	position: absolute;
	top: -50%;
	left: -50%;
	width: 26rem;
	height: 35rem;
	background: linear-gradient(0deg, transparent, transparent, var(--brown-color-2), var(--brown-color-2), var(--brown-color-2));
	z-index: 1;
	transform-origin: bottom right;
	animation: animate 6s linear infinite;
	animation-delay: -4.5s;
}

@keyframes animate {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}

.contain-form {
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
	align-items: center;
}

.form-title {
	font-size: 1.8rem;
	color: var(--brown-color-2);
	text-align: center;
}

.change-login-signup {
	display: flex;
	gap: .5rem;
}

.link-change {
	color: var(--brown-color-3);
	font-weight: 600;
	transition: all .5s ease;
}

.link-change:hover {
	color: var(--brown-color-2);
}

.button-form {
	border: none;
	padding: .5rem 1.5rem;
	font-size: 1.2rem;
	background-color: var(--brown-color-2);
	cursor: pointer;
	transition: all .5s ease;
	color: var(--white-color);
}

.button-form:hover {
	background-color: var(--brown-color-3);
}

.input-contain {
	position: relative;
	width: 100%;
	margin-top: 2rem;
}

.input-contain input {
	position: relative;
	width: 100%;
	padding: 1.1rem .7rem .7rem;
	background: transparent;
	outline: none;
	border: none;
	box-shadow: none;
	font-size: 1em;
	letter-spacing: 0.05em;
	transition: 0.5s;
	z-index: 10;
	color: var(--white-color);
}

.input-contain span {
	position: absolute;
	left: 0;
	padding: 1.1rem .7rem .7rem;
	pointer-events: none;
	font-size: 1em;
	letter-spacing: 0.05em;
	transition: 0.5s;
}

.input-contain input:valid ~ span,
.input-contain input:focus ~ span {
	color: #000;
	font-size: 0.75em;
	transform: translateY(-2rem);
}

.input-contain i {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 2px;
	background-color: var(--brown-color-2);
	border-radius: 4px;
	overflow: hidden;
	transition: 0.5s;
	pointer-events: none;
}

.input-contain input:valid ~ i,
.input-contain input:focus ~ i {
	height: 2.7rem;
}

.password-show-hide {
	position: absolute;
	top: 1rem;
	font-size: 1.2rem;
	right: 1rem;
	z-index: 10;
	transition: all .7s ease;
	color: var(--white-color);
	opacity: 0;
	cursor: pointer;
}

.input-contain input:valid ~ .password-show-hide,
.input-contain input:focus ~ .password-show-hide {
	opacity: 1;
}

.container-activate {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 48.2vh;
}

.activate-button {
	border: none;
	outline: none;
	cursor: pointer;
	font-weight: 600;
	font-size: 1.2rem;
	color: var(--white-color);
	background-color: #C8957E;
	transition: all .5s ease;
	width: 10rem;
	height: 10rem;
	border-radius: 50%;
}

.activate-button:hover {
	background-color: var(--white-color);
	color: #C8957E;
}

.container-signup-success {
	min-height: 48.2vh;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 2rem;
	color: var(--brown-color-3);
}

/********************/
/*     REGISTER     */
/********************/

.container-register-form {
	position: relative;
	width: 26rem;
	height: 50rem;
	background-color: var(--white-color-2);
	border-radius: .5rem;
	overflow: hidden;
}

.container-register-form .form-content {
	position: absolute;
	inset: 4px;
	background-color: var(--white-color);
	padding: 3rem 2.5rem;
	border-radius: .5rem;
	z-index: 2;
	display: flex;
	flex-direction: column;
}

.container-register-form::before {
	content: '';
	position: absolute;
	top: -50%;
	left: -50%;
	width: 26rem;
	height: 50rem;
	background: linear-gradient(0deg, transparent, transparent, var(--brown-color-1), var(--brown-color-1), var(--brown-color-1));
	z-index: 1;
	transform-origin: bottom right;
	animation: animate 6s linear infinite;
}

.container-register-form::after {
	content: '';
	position: absolute;
	top: -50%;
	left: -50%;
	width: 26rem;
	height: 50rem;
	background: linear-gradient(0deg, transparent, transparent, var(--brown-color-1), var(--brown-color-1), var(--brown-color-1));
	z-index: 1;
	transform-origin: bottom right;
	animation: animate 6s linear infinite;
	animation-delay: -3s;
}

.border-line-1 {
	position: absolute;
	top: 0;
	inset: 0;
}

.border-line-1::before {
	content: '';
	position: absolute;
	top: -50%;
	left: -50%;
	width: 26rem;
	height: 50rem;
	background: linear-gradient(0deg, transparent, transparent, var(--brown-color-2), var(--brown-color-2), var(--brown-color-2));
	z-index: 1;
	transform-origin: bottom right;
	animation: animate 6s linear infinite;
	animation-delay: -1.5s;
}

.border-line-1::after {
	content: '';
	position: absolute;
	top: -50%;
	left: -50%;
	width: 26rem;
	height: 50rem;
	background: linear-gradient(0deg, transparent, transparent, var(--brown-color-2), var(--brown-color-2), var(--brown-color-2));
	z-index: 1;
	transform-origin: bottom right;
	animation: animate 6s linear infinite;
	animation-delay: -4.5s;
}

/********************/
/*  RESET-PASSWORD  */
/********************/

.container-reset-form {
	position: relative;
	width: 26rem;
	height: 26rem;
	background-color: var(--white-color-2);
	border-radius: .5rem;
	overflow: hidden;
}

.form-reset-title {
	text-align: center;
	font-size: 1.3rem;
	color: var(--brown-color-2);
}

.container-reset-form .form-content {
	position: absolute;
	inset: 4px;
	background-color: var(--white-color);
	padding: 3rem 2.5rem;
	border-radius: .5rem;
	z-index: 2;
	display: flex;
	flex-direction: column;
}

.container-reset-form::before {
	content: '';
	position: absolute;
	top: -50%;
	left: -50%;
	width: 26rem;
	height: 26rem;
	background: linear-gradient(0deg, transparent, transparent, var(--brown-color-1), var(--brown-color-1), var(--brown-color-1));
	z-index: 1;
	transform-origin: bottom right;
	animation: animate 6s linear infinite;
}

.container-reset-form::after {
	content: '';
	position: absolute;
	top: -50%;
	left: -50%;
	width: 26rem;
	height: 26rem;
	background: linear-gradient(0deg, transparent, transparent, var(--brown-color-1), var(--brown-color-1), var(--brown-color-1));
	z-index: 1;
	transform-origin: bottom right;
	animation: animate 6s linear infinite;
	animation-delay: -3s;
}

.border-line-2 {
	position: absolute;
	top: 0;
	inset: 0;
}

.border-line-2::before {
	content: '';
	position: absolute;
	top: -50%;
	left: -50%;
	width: 26rem;
	height: 26rem;
	background: linear-gradient(0deg, transparent, transparent, var(--brown-color-2), var(--brown-color-2), var(--brown-color-2));
	z-index: 1;
	transform-origin: bottom right;
	animation: animate 6s linear infinite;
	animation-delay: -1.5s;
}

.border-line-2::after {
	content: '';
	position: absolute;
	top: -50%;
	left: -50%;
	width: 26rem;
	height: 26rem;
	background: linear-gradient(0deg, transparent, transparent, var(--brown-color-2), var(--brown-color-2), var(--brown-color-2));
	z-index: 1;
	transform-origin: bottom right;
	animation: animate 6s linear infinite;
	animation-delay: -4.5s;
}

/********************/
/*   PHONE-SCREEN   */
/********************/

@media screen and (max-width: 700px) {
	.container-login {
		padding-bottom: 0;
	}

	.container-signup-success {
		text-align: center;
	}
}