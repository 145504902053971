/********************/
/*     SETTINGS     */
/********************/

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Montserrat', sans-serif;
}

:root {
	--primary-color: #FFEFEF;
	--hover-primary: #D65E76;
	--rose-color: #FCB5B5;
	--secundary-color: #EEC95C;
	--hover-secundary: #C29C73;
	--background-color: #D65E76;
	--background-light: #FFE5BB;
	--white-color: #FFFFFF;
	--white-color-2: #FFF4E1;
	--red-color: #A6192E;
	--light-brown: #F3E3C5;
	--light-brown-2: #FFF6E3;
	--brown-color-1: #D6C6A8;
	--brown-color-2: #8C5A44;
	--brown-color-3: #341F17;
	--light-gray: #F1F1F1;
	--gray-color: #A49694;
	--text-color: #222222;

	/** Essen - Colors **/
	--capri-color: #24A1BA;
	--capri-hover: #0B829A;
	--cherry-color: #562F27;
	--cherry-hover: #391E18;
	--terra-color: #564D44;
	--terra-hover: #3E362E;
	--nuit-color: #2c2a2a;
	--nuit-hover: #202022;
}

.container {
	margin: 0 auto;
	max-width: 95rem;
}

.btn {
	padding: 1rem 2.2rem;
	font-size: 1rem;
	position: relative;
	margin: 2rem;
}

/********************/
/*       ESSEN      */
/********************/

/* Promotions */
.container-essen-promotions {
	display: flex;
	flex-direction: column;
	gap: 3rem;
	justify-content: center;
	align-items: center;
	background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('../images/back-promos.jpg');
	min-height: 92.5vh;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	color: var(--white-color);
}

.container-essen-promotions h3 {
	font-size: 4rem;
}

.container-essen-promotions p {
	font-size: 1.5rem;
}

.btn2 {
	padding: 1rem 2.2rem;
	font-size: 1.5rem;
	position: relative;
	margin: 2rem;
}

/* Products */
.container-essen-products {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	padding: 5rem 12rem;
}

.essen-products-img {
	width: 50rem;
}

.essen-products-img img {
	width: 100%;
}

.contain-essen-products {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 3rem;
}

.contain-essen-products h3 {
	font-size: 3rem;
	color: var(--brown-color-2);
}

.image-essen-logo {
	width: 20rem;
}

/* Essen + */
.container-essen-plus {
	background: url('../images/fondo-essen+.png');
	padding: 7rem 10rem;
	display: flex;
	align-items: center;
	gap: 10rem;
}

.contain-essen-plus {
	display: flex;
	flex-direction: column;
	gap: 3rem;
	align-items: flex-start;
}

.contain-essen-plus h3 {
	font-size: 5rem;
	color: #FF7100;
	font-weight: 600;
	padding-left: 2rem;
}

.essen-plus-text {
	color: var(--white-color);
	font-size: 4.5rem;
	padding-left: 2rem;
}

.essen-plus-text span {
	font-weight: 600;
	color: #53C3FF;
}

/* Fire */
.container-essen-fire {
	display: flex;
	flex-direction: column;
	gap: 2rem;
}

.contain-essen-fire {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 5rem;
	padding: 8rem 5rem;
}

.contain-essen-fire h3 {
	font-size: 3rem;
	color: var(--brown-color-3);
	font-weight: 600;
}

.contain-essen-fire p {
	font-size: 1.1rem;
}

.img-fire {
	width: 8rem;
}

.container-essen-fire-boxes {
	display: flex;
	flex-direction: column;
	gap: 3rem;
	justify-content: center;
	align-items: center;
}

.container-essen-fire-boxes h3 {
	font-size: 3rem;
	color: var(--brown-color-2);
}

.container-grid-animation {
	display: flex;
	flex-wrap: wrap;
	gap: 2rem;
}

.animation-content {
	position: relative;
}

.animation-face {
	width: 20rem;
	height: 15rem;
	transition: all .4s ease;
}

.animation-face.face-1 {
	position: relative;
	background: var(--light-gray);
	display: flex;
	justify-content: center;
	align-content: center;
	align-items: center;
	z-index: 1;
	transform: translateY(100px);
}

.animation-content:hover .animation-face.face-1 {
	transform: translateY(0);
  	box-shadow:
    	inset 0 0 60px whitesmoke,
    	inset 20px 0 80px var(--blue-color),
    	inset -20px 0 80px var(--green-color-1),
    	inset 20px 0 300px var(--blue-color),
    	inset -20px 0 300px var(--green-color-1),
    	0 0 50px #fff,
    	-10px 0 80px var(--blue-color),
    	10px 0 80px var(--green-color-1);
}

.animation-face.face-1 .content {
	opacity: .2;
	transition: all .5s ease;
	text-align: center;
	display: flex;
	flex-direction: column;
	font-size: 1.2rem;
	align-items: center;
	gap: 1rem;
}

.animation-content:hover .animation-face.face-1 .content {
	opacity: 1;
}

.animation-face.face-2 {
	position: relative;
	background: var(--white-color);
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 2rem;
	box-sizing: border-box;
	box-shadow: 0 20px 50px rgba(0,0,0,.8);
  	transform: translateY(-140px);
}

.animation-content:hover .animation-face.face-2 {
	transform: translateY(0);
}

.animation-face.face-2 .content {
	color: var(--brown-color-3);
}

/********************/
/*    PROMOTIONS    */
/********************/

/* Banner */
.container-promotions-banner {
	background: url('../images/promo1.png');
	min-height: 92.5vh;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

/* Promo-Swiper */
.container-promotions-swiper {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	padding: 8rem 12rem;
	gap: 10rem;
	background-color: var(--brown-color-3);
}

.promotions-swiper-text {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	color: var(--white-color);
	padding: 2rem;
	gap: 4rem;
}

.promotions-swiper-text h3 {
	font-size: 3.5rem;
	font-weight: 600;
}

.promotions-swiper-text p {
	font-size: 1.2rem;
}

.contain-promotions-swiper {
	width: 25rem;
	display: flex;
	justify-content: center;
	align-items: center;
}

.container-promotions-news {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--light-gray);
}

.container-promotions-promo2 {
	background: url('../images/promo2.png');
	min-height: 110vh;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

/********************/
/*   PHONE-SCREEN   */
/********************/

@media screen and (max-width: 700px) {
	/* ESSEN */
	/* Promotions */
	.container-essen-promotions {
		text-align: center;
	}

	.container-essen-promotions h3 {
		font-size: 3rem;
	}

	/* Products */
	.container-essen-products {
		display: flex;
		flex-direction: column;
		padding: 5rem 1rem;
		text-align: center;
	}

	.essen-products-img {
		width: 100%;
	}

	/* Essen + */
	.container-essen-plus {
		background: #111D35;
		display: flex;
		flex-direction: column;
		padding: 3rem 1rem;
		gap: 3rem;
		width: 100%;
	}

	.container-essen-plus img {
		width: 100%;
	}

	.essen-plus-text {
		font-size: 3rem;
	}

	/* Fire */
	.contain-essen-fire {
		padding: 5rem 2rem;
		width: 100%;
	}

	.contain-essen-fire img {
		width: 100%;
	}

	.container-essen-fire-boxes {
		display: flex;
		flex-direction: column;
		padding: 0 2rem;
	}

	.container-grid-animation {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	/* PROMOTIONS */

	/* Banner */
	.container-promotions-banner {
		background: url('../images/promo1-phone.png');
		min-height: 82vh;
		background-size: cover;
		background-repeat: no-repeat;
	}

	/* Promo-Swiper */
	.container-promotions-swiper {
		display: flex;
		flex-direction: column;
		padding: 5rem 2rem;
		justify-content: center;
		align-items: center;
	}

	.contain-promotions-swiper {
		width: 20rem;
		flex-direction: column;
	}

	.container-promotions-news {
		width: 100%;
	}

	.container-promotions-news img {
		width: 100%;
	}

	.container-promotions-promo2 {
		background: url('../images/promo2-phone.png');
		background-size: cover;
		background-position: center;
		min-height: 80vh;
	}
}