/********************/
/*     SETTINGS     */
/********************/

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Montserrat', sans-serif;
}

:root {
	--primary-color: #FFEFEF;
	--hover-primary: #D65E76;
	--rose-color: #FCB5B5;
	--secundary-color: #EEC95C;
	--hover-secundary: #C29C73;
	--background-color: #D65E76;
	--background-light: #FFE5BB;
	--white-color: #FFFFFF;
	--white-color-2: #FFF4E1;
	--red-color: #A6192E;
	--light-brown: #F3E3C5;
	--brown-color-1: #D6C6A8;
	--brown-color-2: #8C5A44;
	--brown-color-3: #341F17;
	--light-gray: #F1F1F1;
	--gray-color: #A49694;
	--text-color: #222222;

	/** Essen - Colors **/
	--capri-color: #24A1BA;
	--capri-hover: #0B829A;
	--cherry-color: #562F27;
	--cherry-hover: #391E18;
	--terra-color: #564D44;
	--terra-hover: #3E362E;
	--nuit-color: #2c2a2a;
	--nuit-hover: #202022;
}

.container {
	margin: 0 auto;
	max-width: 95rem;
}

.btn {
	padding: 1rem 2.2rem;
	font-size: 1rem;
	position: relative;
	margin: 1.5rem;
}

/********************/
/*   PRODUCT-CARD   */
/********************/

.container-product-card {
	display: flex;
	flex-direction: column;
	gap: .5rem;
	width: 20rem;
	padding: 1rem;
	background-color: #FFF;
	box-shadow: 0px 0px 15px 0px rgba(69,69,69,0.75);
	-webkit-box-shadow: 0px 0px 15px 0px rgba(69,69,69,0.75);
	-moz-box-shadow: 0px 0px 15px 0px rgba(69,69,69,0.75);
}

.product-card-image {
	width: 100%;
	height: 18rem;
	overflow: hidden;
	position: relative;
}

.product-image-icon {
	display: flex;
	flex-direction: column;
	position: absolute;
	z-index: 100;
	top: 0;
	right: 0;
	font-size: 2rem;
	gap: .5rem;
	opacity: 0;
	transition: all .3s ease;
	cursor: pointer;
}

.product-image-icon:hover {
	color: var(--red-color);
}

.product-card-image img {
	width: 100%;
	height: 100%;
	object-fit: contain;
	transition: all .3s ease;
}

.product-card-info {
	display: flex;
	justify-content: space-between;
	padding-top: .5rem;
}

.product-card-image img:hover {
	transform: scale(1.1);
}

.container-product-card:hover .product-image-icon {
	opacity: 1;
}

.product-card-title {
	transition: all .3s ease;
	color: var(--brown-color-2);
}

.product-card-title:hover {
	color: var(--brown-color-3);
}

.stocked-product {
	position: absolute;
	background-color: var(--background-dark);
	color: var(--light-text);
	z-index: 200;
}

.stocked-text {
	padding: .2rem;
}

.button-bag {
	border: none;
	background-color: transparent;
	font-size: 2rem;
	cursor: pointer;
	transition: all .5s ease;
}

.button-bag:hover {
	color: var(--hover-primary);
}

/********************/
/*   RECIPE-CARD    */
/********************/

.container-recipe-card {
	display: flex;
	flex-direction: column;
	gap: .5rem;
	width: 20rem;
	padding: 1rem;
	background-color: var(--brown-color-1);
}

.recipe-card-image {
	width: 100%;
	height: 18rem;
	overflow: hidden;
	position: relative;
}

.recipe-card-image img {
	width: 100%;
	height: 100%;
	object-fit: contain;
	transition: all .3s ease;
}

.recipe-card-image img:hover {
	transform: scale(1.1);
}

.recipe-card-details {
	display: flex;
	justify-content: center;
	gap: 2rem;
	padding: 0 3rem;
}

.card-details-text {
	display: flex;
	align-items: center;
	font-size: 1rem;
	gap: .2rem;
}

.card-details-icons {
	font-size: 1.5rem;
}

.recipe-card-title {
	transition: all .3s ease;
	color: var(--brown-color-3);
	text-align: center;
}

.recipe-card-title:hover {
	color: var(--brown-color-2);
}

.recipe-card-info {
	height: 3rem;
}

/********************/
/*     BLOG-CARD    */
/********************/

.container-blog-card {
	display: flex;
	flex-direction: column;
	gap: .5rem;
	width: 22rem;
	gap: .5rem;
}

.blog-card-image {
	width: 100%;
	height: 14rem;
	overflow: hidden;
	position: relative;
}

.blog-card-image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	transition: all .3s ease;
}

.link-to-post {
	color: var(--brown-color-2);
	transition: all .5s ease;
}

.link-to-post:hover {
	color: var(--brown-color-3);
}

.recipe-card-details {
	display: flex;
	justify-content: center;
	gap: 2rem;
	padding: 0 3rem;
}

.blog-card-excerpt {
	display: flex;
	flex-direction: column;
	gap: .5rem;
}

.blog-card-date {
	display: flex;
	justify-content: flex-end;
	color: var(--brown-color-2);
}

.blog-excerpt-text {

}

.card-details-text {
	display: flex;
	align-items: center;
	font-size: 1rem;
	gap: .2rem;
}

.card-details-icons {
	font-size: 1.5rem;
}

.recipe-card-title {
	transition: all .3s ease;
	color: var(--brown-color-3);
	text-align: center;
}

.recipe-card-title:hover {
	color: var(--brown-color-2);
}

/********************/
/*   WISH-PRODUCT   */
/********************/

.container-wish-product-card {
	display: flex;
	flex-direction: column;
	gap: .5rem;
	width: 16rem;
	padding: 1rem;
	background-color: var(--brown-color-2);
	border-radius: 1rem;
}

.container-wish-recipe-card {
	display: flex;
	flex-direction: column;
	gap: .5rem;
	width: 16rem;
	padding: 1rem;
	background-color: var(--brown-color-2);
	border-radius: 1rem;
}

.container-wish-product {
	position: relative;
}

.wish-product-image {
	width: 100%;
	height: 10rem;
	overflow: hidden;
	position: relative;
	border-radius: 1rem;
}

.wish-recipe-image {
	width: 100%;
	height: 14rem;
	overflow: hidden;
	position: relative;
	border-radius: 1rem;
}

.wish-product-image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	transition: all .3s ease;
}

.wish-recipe-image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	transition: all .3s ease;
}

.button-remove-contain {
	position: absolute;
	z-index: 10;
	top: 0;
	right: 0;
}

.button-remove-contain button {
	border: none;
	cursor: pointer;
	font-size: 1.8rem;
	color: var(--brown-color-3);
	background-color: transparent;
}

.wish-product-info {
	display: flex;
	align-items: center;
	height: 3rem;
	text-align: center;
}

.wish-card-link {
	padding: .5rem;
	color: var(--white-color);
	text-align: center;
	border-radius: 1rem;
	transition: all .5s ease;
	font-weight: 600;
}

.wish-card-link:hover {
	color: var(--brown-color-1);
}

.wish-item-info {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	justify-content: center;
	align-items: center;
}

.wish-item-recipe-info {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	justify-content: center;
	align-items: center;
}

/********************/
/*    SWIPER-CARD   */
/********************/

.container-swiper-card {
	width: 30rem;
	height: 40rem;
	border-radius: 2rem;
	background-color: var(--brown-color-1);
	padding: 2rem;
}

.container-swiper-recipe {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 1rem;
}

.swiper-recipe-image {
	width: 20rem;
	height: 25rem;
	background-color: var(--white-color);
	border-radius: 2rem;
}

.swiper-recipe-image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 2rem;
}

.swiper-item-info {
	display: flex;
	flex-direction: column;
	gap: 1rem;
}

.swiper-recipe-info,
.swiper-recipe-details {
	color: var(--brown-color-3);
	font-weight: 600;
}

.swiper-recipe-details {
	display: flex;
	gap: 3rem;
	align-items: center;
	justify-content: center;
}

.card-details-text-swiper {
	display: flex;
	align-items: center;
	gap: .5rem;
}

.btn-go-recipe {
	color: var(--brown-color-2);
	font-weight: 600;
	display: flex;
	gap: .5rem;
	padding: .5rem;
	font-size: 1.2rem;
	align-items: center;
	justify-content: center;
}

.icons-to-recipe {
	opacity: 0;
	transition: all .3s ease;
}

.btn-go-recipe:hover .icons-to-recipe {
	opacity: 1;
}

/********************/
/*   PHONE-SCREEN   */
/********************/

@media screen and (max-width: 700px) {

	/* Product-Card */

	.container-product-card {
		width: 11.5rem;
		padding: 1rem 0.3rem;
	}

	.product-card-image {
		height: 12rem;
	}

	.product-card-category {
		font-size: .9rem;
	}

	.product-card-info {
		height: 3rem;
	}

	/* Recipe-Card */

	.container-recipe-card {
		width: 11.5rem;
		padding: 1rem 0.3rem;
	}

	.recipe-card-image {
		height: 14rem;
	}

	.recipe-card-details {
		display: flex;
		flex-direction: column;
		gap: .5rem;
		align-items: center;
		justify-content: center;
		padding: 0 1rem;
	}

	.card-details-text {
		gap: .2rem;
	}

	/* Swiper-Card */

	.container-swiper-card {
		width: 18rem;
		height: 30rem;
		padding: 1rem;
	}

	.swiper-recipe-image {
		width: 14rem;
		height: 15rem;
	}

	/* Wish-Card */

	.container-wish-recipe-card {
		width: 22rem;
		height: 3rem;
		gap: 0;
		padding: 0;
	}

	.cards-contain-wish {
		display: flex;
		flex-wrap: nowrap;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}

	.wish-recipe-image {
		height: 0;
	}

	.wish-recipe-image img {
		display: none;
	}

	.button-remove-contain {
		top: .6rem;
		right: .5rem;
	}

	.wish-item-recipe-info {
		padding-right: 2rem;
	}
}
