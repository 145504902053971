/********************/
/*     SETTINGS     */
/********************/

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Montserrat', sans-serif;
}

:root {
	--primary-color: #FFEFEF;
	--hover-primary: #D65E76;
	--rose-color: #FCB5B5;
	--secundary-color: #EEC95C;
	--hover-secundary: #C29C73;
	--background-color: #D65E76;
	--background-light: #FFE5BB;
	--white-color: #FFFFFF;
	--white-color-2: #FFF4E1;
	--red-color: #A6192E;
	--light-brown: #F3E3C5;
	--brown-color-1: #D6C6A8;
	--brown-color-2: #8C5A44;
	--brown-color-3: #341F17;
	--light-gray: #F1F1F1;
	--gray-color: #A49694;
	--text-color: #222222;

	/** Essen - Colors **/
	--capri-color: #24A1BA;
	--capri-hover: #0B829A;
	--cherry-color: #562F27;
	--cherry-hover: #391E18;
	--terra-color: #564D44;
	--terra-hover: #3E362E;
	--nuit-color: #2c2a2a;
	--nuit-hover: #202022;
}

.container {
	margin: 0 auto;
	max-width: 95rem;
}

.btn {
	padding: 1rem 2.2rem;
	font-size: 1rem;
	position: relative;
	margin: 2rem;
}

/********************/
/*     WHATSAPP     */
/********************/

.whatsapp-button {
	position: fixed;
	bottom: 40px;
	right: 50px;
	display: flex;
	align-items: center;
	gap: 1rem;
	background-color: #23B140;
	padding: .5rem 2rem;
	border-radius: 2rem;
	color: var(--white-color);
	font-size: 1.1rem;
	transition: all .5s ease;
}

.whatsapp-image {
	width: 2rem;
	height: 2rem;
}

.whatsapp-image img {
	width: 100%;
}

.whatsapp-button:hover {
	background-color: #4DFF71;
	color: var(--text-color);
}

/********************/
/*      SWIPER      */
/********************/

.swiper {
	width: 100%;
	height: 100%;
}

.swiper-slide {
	text-align: center;
	font-size: 18px;
	background: transparent;

	/* Center slide text vertically */
	display: flex;
	justify-content: center;
	align-items: center;
}

.swiper-slide img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

/********************/
/*   CONTACT-FORM   */
/********************/

.container-contact-form {
	padding: 5rem 0;
	display: flex;
	gap: 5rem;
	justify-content: center;
	background-color: var(--brown-color-1);
}

.contact-form {
	padding: 0 5rem;
	border-right: 1px solid var(--brown-color-2);
}

.contain-contact-form {
	display: flex;
	flex-direction: column;
	gap: 2rem;
}

.container-contact-divider {
	display: flex;
	gap: 1rem;
}

.input-contact {
	position: relative;
	width: 100%;
	margin-top: 2rem;
}

.input-contact input {
	position: relative;
	width: 100%;
	padding: 1.1rem .7rem .7rem;
	background: transparent;
	outline: none;
	border: none;
	box-shadow: none;
	font-size: 1em;
	letter-spacing: 0.05em;
	transition: 0.5s;
	z-index: 10;
	color: var(--white-color);
}

.input-contact span {
	position: absolute;
	left: 0;
	padding: 1.1rem .7rem .7rem;
	pointer-events: none;
	font-size: 1em;
	font-weight: 600;
	letter-spacing: 0.05em;
	transition: 0.5s;
}

.input-contact input:valid ~ span,
.input-contact input:focus ~ span {
	color: #000;
	font-size: 0.75em;
	transform: translateY(-2rem);
}

.input-contact i {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 2px;
	background-color: var(--brown-color-3);
	border-radius: 4px;
	overflow: hidden;
	transition: 0.5s;
	pointer-events: none;
}

.input-contact input:valid ~ i,
.input-contact input:focus ~ i {
	height: 2.7rem;
}

.input-contact-msg {
	position: relative;
	width: 100%;
	margin-top: 2rem;
}

.input-contact-msg textarea {
	position: relative;
	width: 100%;
	padding: 1.1rem .7rem .7rem;
	background: transparent;
	outline: none;
	border: none;
	box-shadow: none;
	font-size: 1em;
	letter-spacing: 0.05em;
	transition: 0.5s;
	z-index: 10;
	color: var(--white-color);
}

.input-contact-msg span {
	position: absolute;
	left: 0;
	padding: 1.1rem .7rem .7rem;
	pointer-events: none;
	font-size: 1em;
	font-weight: 600;
	letter-spacing: 0.05em;
	transition: 0.5s;
}

.input-contact-msg textarea:valid ~ span,
.input-contact-msg textarea:focus ~ span {
	color: #000;
	font-size: 0.75em;
	transform: translateY(-2rem);
}

.input-contact-msg i {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 2px;
	background-color: var(--brown-color-3);
	border-radius: 4px;
	overflow: hidden;
	transition: 0.5s;
	pointer-events: none;
}

.input-contact-msg textarea:valid ~ i,
.input-contact-msg textarea:focus ~ i {
	height: 4rem;
}

.container-submit-button {
	display: flex;
	justify-content: right;
}

.submit-contact-button {
	padding: .8rem 0;
	width: 10rem;
	background-color: var(--brown-color-3);
	cursor: pointer;
	transition: all .5s ease;
	border: none;
	color: var(--white-color);
	font-weight: 600;
	font-size: 1rem;
}

.submited-contact-button {
	padding: .8rem 0;
	width: 10rem;
	background-color: #23B140;
	border: none;
	color: var(--white-color);
	font-weight: 600;
	font-size: 1rem;
}

.submit-contact-button:hover {
	background-color: var(--brown-color-2);
}

.container-info-contact {
	display: flex;
	flex-direction: column;
	max-width: 25rem;
	padding-top: 2rem;
	gap: 1.5rem;
}

.contain-info-contact {
	display: flex;
	flex-direction: column;
	gap: .5rem;
}

.info-contact-link {
	font-size: 1.3rem;
	color: var(--brown-color-2);
}

.container-contact-social {
	display: flex;
	gap: 2rem;
}

.social-contact-links {
	color: var(--brown-color-3);
	font-size: 1.8rem;
	transition: all .5s ease;
}

/********************/
/*  PARALLAX-SECT   */
/********************/

.parallax-section {
	position: relative;
	height: 100vh;
	overflow-x: hidden;
}

.parallax-background {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('../images/banner-recipe.jpg');
	background-size: cover;
	background-attachment: fixed; /* Fija la imagen de fondo */
	z-index: -1; /* Coloca el fondo detrás del contenido */
}

.parallax-content {
	position: relative;
	z-index: 100; /* Coloca el contenido encima del fondo */
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	align-items: center;
	justify-content: center;
	height: 100%;
	gap: 4rem;
	padding: 0 5rem;
	color: white;
	text-align: center;
}

.parallax-content h3 {
	font-size: 2rem;
}

.parallax-content p {
	font-size: 1.3rem;
}

/********************/
/*   PHONE-SCREEN   */
/********************/

@media screen and (max-width: 700px) {
	.container {
		width: 100%;
	}

	.container-contact-form {
		flex-direction: column;
		width: 100%;
	}

	/* WhatsApp */

	.whatsapp-button {
		font-size: .8rem;
		right: 10px;
		bottom: 20px;
		padding: .5rem 1rem;
	}

	/* Contact */

	.contact-form {
		border: none;
		padding: 0 3rem;
	}

	.container-info-contact {
		max-width: 100%;
		padding: 0 1rem;
		justify-content: center;
		align-items: center;
	}

	.container-contact-main {
		width: 100%;
	}

	.contain-info-contact {
		align-items: center;
	}

	.contain-contact-paragraph {
		padding-top: 2rem;
		border-top: 1px solid var(--brown-color-3);
		display: flex;
		flex-direction: column;
		gap: .5rem;
		text-align: center;
	}

	/* Parallax */ 

	.parallax-section{
		width: 100%;
		height: 100%;
	}

	.parallax-content {
		display: flex;
		flex-direction: column;
		gap: 1rem;
		padding: 1rem 2rem;
	}
}